<template>
  <a-layout class="admin-body">
    <a-layout-sider
      v-model="collapsed"
      class="side-menu-container"
      :trigger="null"
      collapsible
      :style="{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0
      }"
    >
      <SideMenu :collapsed="collapsed" />
    </a-layout-sider>
    <a-layout class="main__content">
      <HzzHeader
        :collapsed="collapsed"
        class="header"
        @changeStatus="collapsed = !collapsed"
      />
      <div class="header-line" />
      <keep-alive>
        <router-view
          v-if="$route.meta.keepAlive"
          :key="$route.path + $route.query.t"
        />
      </keep-alive>
      <router-view
        :key="$route.path + $route.query.t"
        v-if="!$route.meta.keepAlive"
      />
    </a-layout>
  </a-layout>
</template>

<script>
import HzzHeader from "@/components/HzzHeader";
import SideMenu from "@/components/SideMenu";

export default {
  components: {
    HzzHeader,
    SideMenu
  },
  data() {
    return {
      collapsed: false,
      collapsedChange: {}
    };
  },
  methods: {
    onChange() {
      this.collapsed = !this.collapsed;
      if (this.collapsed === true) {
        this.collapsedChange = {
          minWidth: "260px"
        };
      } else {
        this.collapsedChange = {
          minWidth: "60px",
          width: "60px",
          maxWidth: "60px"
        };
      }
    }
  }
};
</script>

<style scoped>
.admin-body {
  min-width: 1400px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}
/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

/deep/.ant-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}
/deep/ .end-search {
  display: flex;
  justify-content: flex-end;
}
/deep/ .ant-layout-sider {
  /*background-color: #001529;*/
  color: white;
  min-height: 100vh;
  z-index: 1000;
}
/deep/.card-item .card-item-title-content:first-child {
  color: rgba(102, 102, 102, 0.8);
}
/deep/ .ant-layout-header {
  padding-left: 25px;
}
.main__content {
  overflow-x: unset;
}
/deep/.card-container {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}
.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.advanced-search-form .ant-form-item {
  display: flex;
}
/deep/.search__btn {
  margin-top: 3px;
}
/deep/ .nav {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  background-color: white;
  box-shadow: #efefef 3px 3px;
}
.header-line {
  height: 1px;
  width: 100%;
  background-color: #ededed;
}
/deep/ .ant-table-thead > tr > th {
  background-color: rgba(55, 105, 255, 0.1);
  white-space: nowrap;
}
</style>
