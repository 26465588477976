<template>
  <div>
    <div class="title-container">
      <div class="logo-container">
        <img round src="/dxlogo.png" alt="logo" />
      </div>

      <div v-if="!collapsed" class="title-content">
        电行世界
      </div>
    </div>
    <a-menu
      mode="inline"
      theme="dark"
      :default-open-keys="openKeys"
      :default-selected-keys="[$store.state.selectedSubmenuId]"
      :selected-keys="[$store.state.selectedSubmenuId]"
      :style="{ height: '100%', borderRight: 0 }"
    >
      <template v-for="menuItem in $store.state.curSubmenus">
        <a-menu-item
          v-if="!menuItem.subMenus"
          :key="menuItem.menuId"
          @click="onSubMenu(menuItem)"
        >
          <my-icon v-if="menuItem.icon" :type="'icon-' + menuItem.icon" />
          <span style="font-size: 16px">{{ menuItem.name }}</span>
        </a-menu-item>

        <a-sub-menu v-if="menuItem.subMenus" :key="menuItem.menuId">
          <span slot="title">
            <span style="font-size: 16px">
              <my-icon v-if="menuItem.icon" :type="'icon-' + menuItem.icon" />
              <span>{{ menuItem.name }}</span>
            </span>
          </span>
          <a-menu-item
            v-for="menuSubItem in menuItem.subMenus"
            :key="menuSubItem.menuId"
            style="font-size: 14px"
            @click="onSubMenu(menuSubItem)"
          >
            <my-icon v-if="menuSubItem.icon" :type="'icon-' + menuItem.icon" />
            <span>{{ menuSubItem.name }}</span>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  props: ["collapsed"],
  data() {
    return {
      theme: "light",
      openKeys: JSON.parse(localStorage.getItem("openKeys"))
    };
  },
  methods: {
    onSubMenu(menu) {
      this.$store.dispatch("setCurSubMenu", menu.menuId);
      const path = menu.path;
      if (path && menu.path.includes(":")) {
        const name = path.split("#")[0];
        const tmpStr = path.split("#")[1];
        const value = tmpStr.split(":")[1];
        this.$router.replace({ name: name, params: { type: value } });
      } else {
        this.$router.push({ name: menu.path });
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
/*/deep/ .ant-menu {*/
/*  background-color: #001529;*/
/*}*/

.title-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.logo-container {
  width: 42px;
  margin-right: 15px;
  margin-left: 2px;
}
.logo-container img {
  width: 100%;
  object-fit: contain;
}
.icon-bg {
  width: 20px;
  object-fit: contain;
}
.title-content {
  font-size: 20px;
  letter-spacing: 2px;
}
/*/deep/ .ant-menu-dark .ant-menu-item {*/
/*  color: white;*/
/*}*/
</style>
