<template>
  <a-layout-header>
    <a-row class="row">
      <a-col :span="1">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          style="font-size: 20px"
          @click="onChangeStatus"
        />
      </a-col>
      <a-col :span="18">
        <div class="menu__container">
          <a-menu
            mode="horizontal"
            theme="dark"
            :style="{ lineHeight: '64px' }"
            :selected-keys="[$store.state.selectedTopMenuId]"
          >
            <a-menu-item
              v-for="menuItem in $store.state.userMenu"
              :key="menuItem.menuId"
              @click="openSubmenu(menuItem.menuId)"
            >
              <a-icon v-if="menuItem.icon" :type="menuItem.icon" :style="{ fontSize: '18px' }" />
              {{ menuItem.name }}
            </a-menu-item>
          </a-menu>
        </div>
      </a-col>
      <a-col :span="4" class="hzz-right">
        <div class="options-container">
          <a-dropdown :placement="dropdownMenuPlacement">
            <a @click="e => e.preventDefault()">
              <a-avatar class="name-color" icon="user" />
              <span class="user-name">{{ realName }}</span>
            </a>
            <a-menu slot="overlay" class="menu-margin-top">
              <a-menu-item>
                <a @click="logout">
                  <a-icon type="logout" />
                  退出登录
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import defaultAvatar from "../assets/imgs/default-avatar.png";
import { getCurrentUserPermissions } from "../services/ValueBag";

export default {
  props: ["collapsed"],
  data() {
    return {
      userAvatar: defaultAvatar,
      dropdownMenuPlacement: "bottomRight",
      realName: ""
    };
  },
  created() {
    // 根据权限更新菜单列表
    this.updatePermission();
  },
  mounted() {
    this.realName = localStorage.getItem("realName");
    this.userMenu = JSON.parse(localStorage.getItem("userMenu"));
  },
  methods: {
    onChangeStatus() {
      this.$emit("changeStatus");
    },
    logout(e) {
      e.preventDefault();
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
    openSubmenu(menuId) {
      this.$store.dispatch("setCurTopMenuKey", menuId);
      for (const menu of this.$store.state.userMenu) {
        if (menu.menuId === menuId) {
          if (menu.path) {
            this.$store.dispatch("setCurSubMenuList", menu.subMenus);
            this.$router.push({ name: menu.path });
          } else if (menu.subMenus && menu.subMenus[0].path) {
            this.$store.dispatch("setCurSubMenuList", menu.subMenus);
            const path = menu.subMenus[0].path;
            this.$router.push({ name: path });
          } else if (menu.subMenus && menu.subMenus[0].subMenus) {
            this.$store.dispatch("setCurSubMenuList", menu.subMenus);
            const path = menu.subMenus[0].subMenus[0].path;
            this.$router.push({ name: path });
          }
          break;
        }
      }
    },
    async updatePermission() {
      const { data } = await getCurrentUserPermissions();
      // 没有菜单了 强制退出
      if (!data.data.length) {
        // 退出登录
        localStorage.clear();
        this.$router.push({ name: "Login" });
        return;
      }
      this.$store.dispatch("setUserMenu", data.data);
      const currentTopMenu = data.data.find(
        item => item.menuId === this.$store.state.selectedTopMenuId
      );
      // // 如果主菜单还有
      if (currentTopMenu) {
        // 更新子菜单
        this.$store.dispatch("setCurSubMenuList", currentTopMenu.subMenus);
      } else {
        // 没有 回到第一个
        this.$store.dispatch("setCurSubMenuList", data.data[0].subMenus);
      }
    }
  }
};
</script>

<style scoped>
.logo {
  width: auto;
  /*margin-right: 50px;*/
}

.logo-pic {
  width: 50px;
  position: relative;
  top: -5px;
}

.logo-title {
  font-size: 20px;
  color: #fff;
  margin-left: 6px;
  font-weight: 600;
}

.user-name {
  color: #ffffff;
  padding-left: 6px;
}
.options-container {
  font-size: 12px;
  text-align: end;
}
#layout-header {
  /*background-image: url("../assets/imgs/header-bg.png");*/
}
.hzz-right {
  text-align: right;
}

.name-color {
  background-color: #87d068;
}

.menu-margin-top {
  margin-top: 6px;
}

.row {
  display: flex;
}

.trigger {
  color: white;
}
</style>
